import {OrganizationCache} from 'components/service/organization.cache';
import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from 'system/systemPropertyService';
import {CheckClearingGroup} from '../../../../service/check.type';
import {Amortization, Loan} from '../../../../service/loan.types';
import templateUrl from './loan-issue-pdc.template.html';
import {sum} from '../../../../../shared/common/MathUtils';
import moment, {Moment} from 'moment';
import DepositoryAccount from "check/DepositoryAccountTypes";
import {ILocationService} from "angular";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {DepositoryAccountCache} from "components/service/misc-transaction/depository-account.cache.types";
import {SystemDateService} from "components/service/system-date.service.types";
import {Dict} from "shared/common/dict.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import {
  DictionaryIssuingBank
} from "components/dashboard/miscellaneous-transactions/funds-movement/funds-movement.component";
import {NxIFilterService} from "components/technical/angular-filters";

class LoanPdcInput {
  constructor(
    public readonly amortization: Amortization,
    public checkDate: string,
    public amount: number,
    public micrNumber: string,
    public bankId: number
  ) {
  }
}

class LoanIssuePdc {

  // Constants
  readonly pdcClearingGroups = Object.freeze([
    { value: CheckClearingGroup.OUTWARD, label: 'Outward' },
    { value: CheckClearingGroup.ON_US, label: 'On-us' }
  ]);
  readonly selectConfig = {
    placeholder: 'Select bank',
    searchField: ['description'],
    valueField: 'id',
    labelField: 'description',
    maxItems: 1
  };

  private readonly customerId!: number;
  private readonly loan!: Loan;

  banks!: DictionaryIssuingBank[];
  systemDate!: Moment;
  depositoryAccounts!: DepositoryAccount[];
  checksCount: number = 0;
  firstCheckNumber?: number;
  checksClearingGroup?: CheckClearingGroup;
  bankId?: number;
  brstn?: string;
  accountNumber?: string;
  depositoryAccountId?: number;
  pdcInputList: LoanPdcInput[] = [];
  validAmortizations: Amortization[] = [];
  totalAmortizationPdcAmount: number = 0;
  totalAmortizationPdcBalance: number = 0;
  isPchcDirectClearingEnabled!: boolean;
  organizationBankId?: number;
  isOnUsAndNotDirectClearing: boolean = false;

  constructor(private readonly $filter: NxIFilterService,
              private readonly $location: ILocationService,
              private readonly command: CommandService,
              private readonly depositoryAccountCache: DepositoryAccountCache,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly systemDateService: SystemDateService,
              private readonly dict: Dict,
              private readonly customerCache: CustomerCache,
              private readonly organizationCache: OrganizationCache) {
  }

  async $onInit(): Promise<void> {
    const [allDepositoryAccounts, systemDate, organizations] = await Promise.all([
      this.depositoryAccountCache.toPromise(),
      this.systemDateService.getSystemDateByBranchId(this.loan.branchId),
      this.organizationCache.toPromise(),
      this.dict.onLoadingCompleteAsync()
    ]);

    this.depositoryAccounts = allDepositoryAccounts.filter((da : DepositoryAccount) => da.clearing);
    this.systemDate = systemDate;
    this.validAmortizations = this.getValidAmortizations();
    this.banks = this.dict['BANK'];
    this.isPchcDirectClearingEnabled = systemPropertyService.getProperty('PCHC_DIRECT_CLEARING_MEMBER') === 'TRUE';
    this.organizationBankId = organizations.find(o => o.root)?.bankId;
  }

  private getValidAmortizations(): Amortization[] {
    return this.loan.amortizationSchedule.list
      .filter(a => a.status !== 'PAID')
      .filter(a => {
        return moment(a.dueDate).isAfter(this.systemDate);
      });
  }

  checkClearingGroupChanged(): void {
    this.brstn = undefined;
    this.accountNumber = undefined;
    this.depositoryAccountId = undefined;
    if (this.isPchcDirectClearingEnabled && this.checksClearingGroup === 'ON_US') {
      this.bankId = this.organizationBankId;
    } else {
      this.bankId = undefined;
    }
    this.isOnUsAndNotDirectClearing = this.checksClearingGroup === 'ON_US' && !this.isPchcDirectClearingEnabled;
  }

  depositoryAccountChanged(): void {
    if (this.depositoryAccountId) {
      const account = <DepositoryAccount> this.depositoryAccounts.find(d => d.id === this.depositoryAccountId);
      this.brstn = account.brstn;
      this.accountNumber = account.accountNumber;
      this.bankId = account.bankId;
    }
  }

  private generateMicr(pdcIndex: number): string {
    const checkNo = Number(this.firstCheckNumber) + pdcIndex;
    const checkNoPadded = _.padStart(String(checkNo), 10, '0');
    return checkNoPadded + this.brstn + this.accountNumber;
  }

  /**
   * Note: The PDC values are intentionally based on the amortization amount (not balance!).
   * In terms of bank processes, PDCs are issued based off of the amortization schedule - the agreement between client and bank on
   * what should be paid (and on what frequency). Any discrepancies in amortization balance vs amount caused by other operations (e.g. partial payments, edit amortization, as earned interest)
   * should be manually adjusted by the user.
   */
  async populate(): Promise<void> {
    if(!this.bankId) {
      return;
    }

    this.pdcInputList = [];
    let checkCounter = 0;
    for (const amortization of this.validAmortizations) {
      if (checkCounter >= this.checksCount) break;

      this.pdcInputList.push(new LoanPdcInput(
        amortization,
        amortization.dueDate,
        amortization.amortizationAmount,
        this.generateMicr(checkCounter),
        this.bankId
      ));

      checkCounter++;
    }
    this.totalAmortizationPdcAmount = sum(this.pdcInputList.map(pdcInput => pdcInput.amortization.amortizationAmount));
    this.totalAmortizationPdcBalance = sum(this.pdcInputList.map(pdcInput => pdcInput.amortization.amortizationBalance));
  }

  remove(amortizationId: number): void {
    this.pdcInputList = _.remove(this.pdcInputList, (pdc) => {
      return pdc.amortization.id !== amortizationId;
    });
  }

  redirectBack(): void {
    this.$location.path(`/customer/${ this.customerId }/loans/${ this.loan.id }`);
  }

  async issue(): Promise<void> {

    const proceed = await this.confirmationTemplate({
      question: `Do you want to issue ${ this.pdcInputList.length } PDC for loan ${ this.loan.productNumber }?`
    });

    if (!proceed) return;

    const { approvalRequired } = await this.command.execute(
      'BatchCreateLoanPDC',
      {
        checks: this.pdcInputList.map(i => {
          return {
            amortizationId: i.amortization.id,
            amount: i.amount,
            validFrom: this.$filter('nxDate')(i.checkDate),
            micrNumber: i.micrNumber,
            bankId: i.bankId
          };
        })
      }
    ).toPromise();

    if (!approvalRequired) {
      this.customerCache.loanPDCs(this.customerId, this.loan.id).refetch();
      this.redirectBack();
    }
  }

  sumPdcAmounts(): number {
    return sum(this.pdcInputList.map(pdcInput => pdcInput.amount));
  }
}

nxModule.component('loanIssuePdc', {
  templateUrl,
  controller: LoanIssuePdc,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
