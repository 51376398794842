import BigNumber from 'bignumber.js';

import {creditingStrategyMapping, cycleType, maturityStrategyMapping} from 'constants/deposit';
import customFieldService from 'custom-field/CustomFieldService';
import _ from 'lodash';
import moment from 'moment';
import nxModule from 'nxModule';
import systemPropertyService from '../../../../../react/system/systemPropertyService';
import {
  isCorporate,
  mapSignatoryToOwnership,
  multipleAccountOwnershipsEnabled,
  validateOwnerConfiguration
} from '../../common/casa-product-util';
import CASA_CLASS_CODES from '../../common/types/casa-class-codes.ts';

import templateUrl from './edit-term-deposit.template.html';

nxModule.component('customerTermDepositEdit', {
  templateUrl: templateUrl,
  controller: function($scope, $route, $location, $filter, customerCache, casaTypesCache, confirmation,
                       branchService, termDepositsService, customerDepositService, command, dict) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];
    const preterminationFirstHalfMultiplier = new BigNumber(0.25).toNumber();
    const preterminationSecondHalfMultiplier = new BigNumber(0.50).toNumber();

    that.termDeposit = {};
    that.casaClassCodes = CASA_CLASS_CODES;
    that.pdicCasaTypes = [];
    that.accounts = [];
    that.input = null;
    that.selectedRelatedAccount = {};
    that.hasValidOwnerConfiguration = false;
    that.cycleTypes = cycleType;

    that.accountSelectConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'label',
      maxItems: 1,
      allowEmptyOption: true
    };

    that.initialPlacementTypes = [{
      label: 'Fixed amount',
      value: 'FIXED_AMOUNT'
    }, {
      label: 'First pledge amount',
      value: 'FIRST_PLEDGE_AMOUNT'
    }];

    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile,
        that.input,
        that.pdicCasaTypes);
      that.isCorporate = isCorporate(that.profile, that.input, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.input.signatoryIds = [];
      }
      if (!that.multipleAccountOwnershipEnabled) {
        that.input.ownership = that.input.ownership.filter(owner => {
          return owner.ownershipType === 'OWNER';
        });
      }
      that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.input, that.pdicCasaTypes);
    };

    $scope.$watchCollection('$ctrl.input.ownership', () => {
      if (that.input) {
        searchAccounts(that.input.ownership,
          that.profile.relatedCustomers,
          that.termDepositProduct.interCustomerTransferAllowed);
        that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.input, that.pdicCasaTypes);
      }
    });

    that.updatePreterminationRates = () => {
      if (that.input.depositInterest.specialInterestRate && that.input.autoCalculatePreterminationInterestRates) {
        const interestRate = new BigNumber(that.input.depositInterest.interestRate);
        that.input.depositInterest.preterminationFirstHalfInterestRate = interestRate.multipliedBy(
          preterminationFirstHalfMultiplier).toNumber();
        that.input.depositInterest.preterminationSecondHalfInterestRate = interestRate.multipliedBy(
          preterminationSecondHalfMultiplier).toNumber();
      }
    };

    that.termEditingDisabled = () => {
      const termEditingRestricted = systemPropertyService.getProperty('RESTRICT_TERM_EDITING_TO_ACTIVATION_DATE') === 'TRUE';
      if (termEditingRestricted && that.termDeposit.activatedOn) {
        return moment(that.branchSystemDate).format('YYYY-MM-DD') !== that.termDeposit.activatedOn;
      }
      return false;
    };

    that.getCycleTypeLabel = (type) => {
      const cycleType = _.find(that.cycleTypes, {value: type});
      return cycleType ? cycleType.label : '-';
    };

    const searchAccounts = (ownership, relatives, fetchRelatedCustomerAccounts, cb) => {
      const customerIds = ownership.map(o => Number(o.customerId));

      customerDepositService.searchDeposits(
        // term deposit owner, co-owner, and related customers Ids
        customerIds,
        // valid deposit account statuses
        ['ACTIVE', 'INACTIVE'],
        // if true -> fetch deposit accounts of related customers,
        fetchRelatedCustomerAccounts,
        // search success callback
        deposits => {
          that.accounts = deposits;
          if (cb) {
            cb(that.accounts);
          }
        }
      );
    };

    that.findSignatoryRelatives = () => {
      const signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.signatoryRelatives = _.filter(that.profile.relatedCustomers, {typeId: signatoryTypeId});
    };

    that.$onInit = async () => {
      const [deposits, products, profile, casaTypes, branches, customFieldValues] = await Promise.all([
        customerCache.termDeposits(customerId).toPromise(),
        termDepositsService.toPromise(),
        customerCache.profile(customerId).toPromise(),
        casaTypesCache.toPromise(),
        branchService.toPromise(),
        customFieldService.readValuesMap({productId: depositId})
      ]);

      const termDeposit = _.find(deposits, {id: Number(depositId)});
      let termDepositProduct = _.find(products, {id: termDeposit.typeId});
      termDepositProduct = termDepositsService.enrichWithStrategiesDescription(termDepositProduct);
      that.termDepositProduct = termDepositProduct;
      that.maturityStrategiesValidValues = termDepositProduct.maturityStrategies.map(v => v.value);
      that.creditingStrategiesValidValues = termDepositProduct.creditingStrategies.map(v => v.value);

      termDeposit.maturityStrategyLabel = maturityStrategyMapping[termDeposit.maturityStrategy];
      termDeposit.creditingStrategyLabel = creditingStrategyMapping[termDeposit.creditingStrategy];

      that.profile = profile;

      searchAccounts(termDeposit.ownership,
        that.profile.relatedCustomers,
        that.termDepositProduct.interCustomerTransferAllowed,
        () => {
          that.termDeposit.selectedRelatedAccount = _.find(that.accounts, {id: termDeposit.relatedAccountId});
          that.selectedRelatedAccount = termDeposit.selectedRelatedAccount;
        });

      dict.onLoadingComplete(() => {
        that.findSignatoryRelatives();
      });

      that.signatoryNames = termDeposit.ownership
        .filter(o => o.ownershipType === 'SIGNATORY')
        .map(o => o.effectiveName);

      that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC', 'customerType': that.profile.customerType});

      if (that.profile?.corporateData?.businessTypeId !== dict.getId('BUSINESS_TYPE', 'SOLE_PROPRIETORSHIP')) {
        that.pdicCasaTypes = that.pdicCasaTypes.filter(t => t.code !== 'CORP_SSP');
      }

      if (termDeposit && termDeposit.pdicTypeId) {
        termDeposit.pdicTypeName = _.find(that.pdicCasaTypes, {id: termDeposit.pdicTypeId})?.name;
      }
      const branch = _.find(branches, {id: termDeposit.branchId});
      if (branch.systemDate) {
        that.branchSystemDate = moment(branch.systemDate).toDate();
      } else {
        that.branchSystemDate = Date();
      }

      that.termDeposit = termDeposit;
      that.input = {
        productId: termDeposit.id,
        typeId: that.termDepositProduct.id,
        pdicTypeId: termDeposit.pdicTypeId,
        depositTerm: termDeposit.depositTerm,
        maturityStrategy: termDeposit.maturityStrategy,
        creditingStrategy: termDeposit.creditingStrategy,
        relatedAccountId: termDeposit.relatedAccountId,
        taxExempted: termDeposit.taxExempted,
        casaClassCode: termDeposit.casaClassCode,
        creditingInterval: termDeposit.creditingScheduler.interval,
        creditingCycleType: termDeposit.creditingScheduler.cycleType,
        lastCreditingDate: termDeposit.creditingScheduler.lastExecution,
        accruedInterest: termDeposit.accruedInterest,
        lastAccrualDate: termDeposit.lastAccrualDate,
        initialDepositDate: termDeposit.initialDepositDate,
        ownership: termDeposit.ownership,
        signatoryIds: termDeposit.ownership.filter(o => o.ownershipType === 'SIGNATORY').map(o => o.relativeId),
        depositInterest: angular.copy(termDeposit.depositInterest),
        autoCalculatePreterminationInterestRates: false,
        extraNumber: termDeposit.extraNumber,
        keepSpecialInterestRateEnabled: termDeposit.keepSpecialInterestRateEnabled,
        initialPlacementType: termDeposit.initialPlacementType,
        categoryIds: termDeposit.categoryIds,
        customFieldValues: customFieldValues
      };
      that.pdicCasaTypesChanged();
    };

    that.resetInterestRate = () => {
      if (that.input && !that.input.depositInterest.specialInterestRate) {
        that.input.depositInterest.interestRate = that.termDeposit.depositInterest.interestRate;
      }
    };

    that.redirect = () => {
      $location.path(`/customer/${customerId}/term-deposits/${depositId}`);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        that.redirect();
      });
    };

    that.update = () => {
      that.resetInterestRate();
      const docStampMessage = willUpdateDocStampAndInterest()
        ? 'The term of the deposit was changed. This will result in recalculating the interest ' +
        'rate as well as reverting and recalculating existing Doc Stamp fees. '
        : '';
      confirmation(docStampMessage + `Do you want to update the "${that.termDeposit.productNumber}" term deposit?`,
        () => {
          const relatedAccount = that.selectedRelatedAccount;
          that.input.relatedAccountId = relatedAccount ? relatedAccount.id : null;

          const signatories = that.signatoryRelatives
            .filter(s => that.input.signatoryIds.includes(s.id))
            .map(sig => mapSignatoryToOwnership(sig));

          if (!(that.multipleAccountOwnershipEnabled || that.isCorporate)) {
            //remove everyone but owner
            that.input.ownership = that.input.ownership.filter(o => o.ownershipType === 'OWNER');
          }

          that.input.ownership = that.input.ownership
            .filter(o => o.ownershipType !== 'SIGNATORY')
            .concat(signatories);

          command.execute('UpdateDeposit', that.input).success(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirect();
          });
        });
    };

    const willUpdateDocStampAndInterest = () => {
      if (!that.termDepositProduct.recalculateDocStampAndInterestOnTermChange) {
        return false;
      }
      const rolloverMode = systemPropertyService.getProperty('DEPOSIT_BATCH_ROLLOVER_MODE');
      const maturityEditDate = rolloverMode === 'EARLY'
        ? that.termDeposit.maturityDate
        : that.termDeposit.maturityDate.add(1, 'days');
      const branchDate = moment(that.branchSystemDate).format('YYYY-MM-DD');
      if (branchDate !== that.termDeposit.createdOn && branchDate !== maturityEditDate) {
        return false;
      }
      return that.input.depositTerm !== that.termDeposit.depositTerm && that.termDeposit.status === 'ACTIVE';
    };
  }
});
