import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import moment from 'moment';
import angular from 'angular';
import _ from 'lodash';
import {
  isCorporate,
  mapSignatoryToOwnership,
  multipleAccountOwnershipsEnabled,
  validateOwnerConfiguration
} from '../../common/casa-product-util';
import CASA_CLASS_CODES from '../../common/types/casa-class-codes.ts';
import {interestCycleTypes} from 'constants/account';

import templateUrl from './edit-account.template.html';

nxModule.component('customerAccountEdit', {
  templateUrl: templateUrl,
  controller: function($route, $location, $filter, customerCache, casaTypesCache, confirmation,
                       branchService, command, $scope, dict, depositAccountTypeService, interestBoardService) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.casaClassCodes = CASA_CLASS_CODES;
    that.pdicCasaTypes = [];
    that.accounts = [];
    that.command = {};
    that.hasValidOwnerConfiguration = false;
    that.accountSelectConfig = {
      placeholder: 'Select account',
      allowEmptyOption: true
    };
    that.cycleTypes = interestCycleTypes;

    that.$onInit = () => {
      dict.onLoadingComplete(async () => {
        await that.loadAccount();
      });
    };

    $scope.$watchCollection('$ctrl.command.ownership', () => {
      if (that.command) {
        that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.command, that.pdicCasaTypes);
      }
    });

    that.loadAccount = async () => {
      const [accounts, branches, profile, casaTypes, accountTypes, customFieldValues] = await Promise.all([
        customerCache.depositAccounts(customerId).toPromise(),
        branchService.toPromise(),
        customerCache.profile(customerId).toPromise(),
        casaTypesCache.toPromise(),
        depositAccountTypeService.toPromise(),
        customFieldService.readValuesMap({productId: accountId})]);

      const account = _.find(accounts, {id: Number(accountId)});
      //remove edited accountID from the list of related accounts
      that.accounts = _.filter(accounts, a => a.id != accountId);
      that.accounts = _.filter(that.accounts, a => a.status === 'ACTIVE');

      const branch = _.find(branches, {id: account.branchId});
      if (branch.systemDate) {
        that.branchSystemDate = moment(branch.systemDate).toDate();
      } else {
        that.branchSystemDate = Date();
      }

      let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.profile = profile;
      that.signatoryRelatives = _.filter(profile.relatedCustomers, {typeId: signatoryTypeId});
      that.signatoryNames = account.ownership
        .filter(o => o.ownershipType === 'SIGNATORY')
        .map(o => o.effectiveName);

      that.pdicCasaTypes = _.filter(casaTypes, {
        'regulatorType': 'PDIC',
        'customerType': that.profile.customerType
      });

      if (that.profile?.corporateData?.businessTypeId !== dict.getId('BUSINESS_TYPE', 'SOLE_PROPRIETORSHIP')) {
        that.pdicCasaTypes = that.pdicCasaTypes.filter(t => t.code !== 'CORP_SSP');
      }

      that.accountType = accountTypes.find(t => t.id === account.typeId);

      that.account = account;
      that.command = {
        productId: accountId,
        casaClassCode: account.casaClassCode,
        pdicTypeId: account.pdicTypeId,
        taxExempted: account.taxExempted,
        accruedInterest: account.accruedInterest,
        lastAccrualDate: account.lastAccrualDate,
        lastInterestApplication: account.lastInterestApplication,
        lastDormancyCharge: account.lastDormancyCharge,
        lastMaintainingCharge: account.lastMaintainingCharge,
        relatedAccountId: account.relatedAccountId,
        ownership: account.ownership,
        signatoryIds: account.ownership.filter(o => o.ownershipType === 'SIGNATORY').map(o => o.relativeId),
        extraNumber: account.extraNumber,
        initialDepositPerformed: account.initialDepositPerformed,
        initialDepositDate: account.initialDepositDate,
        interestRate: account.interestRate,
        maintainingBalance: account.maintainingBalance,
        interestMinimalBalance: account.interestMinimalBalance,
        maxCreditBalance: account.maxCreditBalance,
        creditingScheduler: angular.copy(account.creditingScheduler),
        categoryIds: account.categoryIds,
        customFieldValues: customFieldValues
      };

      that.pdicCasaTypesChanged();

      // interestBoardService.formatInterestRateRangeForRateType(p.interestBoards)
      const interestRateFromBoard = interestBoardService.formatInterestRateRangeForRateType(that.accountType.interestBoards);
      that.account.currentInterest = that.account.interestRate ? that.account.interestRate : interestRateFromBoard;

      const maintainingBalanceFromProduct = that.accountType.maintainingBalance;
      that.account.currentMaintaningBalance = that.account.maintainingBalance
        ? that.account.maintainingBalance
        : maintainingBalanceFromProduct;

      // In case [maxCreditBalance] is defined on the account instance level (0 is also applicable)
      // then system should use it. Otherwise account type level configuration should be used.
      const maxCreditBalanceFromAcct = that.account.maxCreditBalance;
      if (maxCreditBalanceFromAcct || maxCreditBalanceFromAcct === 0) {
        that.account.currentMaxCreditBalance = maxCreditBalanceFromAcct;
      } else {
        that.account.currentMaxCreditBalance = that.accountType.maxCreditBalance;
      }

      const minInterestBalanceFromProduct = that.accountType.interestMinimalBalance;
      that.account.currentInterestMinimalBalance = that.account.interestMinimalBalance
        ? that.account.interestMinimalBalance
        : minInterestBalanceFromProduct;
    };

    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile,
        that.command,
        that.pdicCasaTypes);
      that.isCorporate = isCorporate(that.profile, that.command, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.command.signatoryIds = [];
      }

      if (!that.multipleAccountOwnershipEnabled) {
        that.command.ownership = that.command.ownership.filter(owner => {
          return owner.ownershipType === 'OWNER';
        });
      }
      that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.command, that.pdicCasaTypes);
    };

    that.redirect = () => {
      $location.path(`/customer/${customerId}/accounts/${accountId}`);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        that.redirect();
      });
    };

    that.update = () => {
      const creditingRelatedInfoHasChanged = that.account.creditingScheduler.interval !== that.command.creditingScheduler.interval
        || that.account.creditingScheduler.cycleType !== that.command.creditingScheduler.cycleType
        || that.account.creditingScheduler.referenceDate !== that.account.initialDepositDate
        || that.account.creditingScheduler.lastExecution !== that.account.lastInterestApplication;

      const message = `${creditingRelatedInfoHasChanged
        ? 'Changes in initial deposit date, last interest application date ' +
        'or crediting cycle settings may change the next crediting execution date. '
        : ' '}
        Do you want to update the "${that.account.productNumber}" account?`;

      confirmation(message, () => {
        removeTimeFromDate(that.command);

        const commandInput = angular.copy(that.command);

        const signatories = that.signatoryRelatives
          .filter(s => commandInput.signatoryIds.includes(s.id))
          .map(sig => mapSignatoryToOwnership(sig));

        if (!(that.multipleAccountOwnershipEnabled || that.isCorporate)) {
          //remove everyone but owner
          that.command.ownership = that.command.ownership.filter(o => o.ownershipType === 'OWNER');
        }

        commandInput.ownership = that.command.ownership
          .filter(o => o.ownershipType !== 'SIGNATORY')
          .concat(signatories);

        command.execute('UpdateAccount', commandInput, {nxLoaderText: 'Processing operation...'}).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          that.redirect();
        });
      });
    };

    //private methods

    const removeTimeFromDate = (object) => {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          if (value instanceof Date) {
            object[key] = $filter('nxDate')(value);
          }
        }
      }
    };
  }
});
