import nxModule from 'nxModule';
import {printParameterTranslation} from "constants/print";

import templateUrl from './prints-details-general-configuration.template.html'
import {getAttr as getAttrFromPrint, getAttrBoolValue} from "./print.utils";

import './prints-details-general-configuration.style.less';
import {Print, PrintAttribute, PrintAttributeType} from "print/PrintType";

class PrintsDetailsGeneralConfiguration {
  private printParameters!: Record<string, unknown>;
  private print!: Print;
  private onChange!: () => void;

  private readonly printTypes = [
    {
      label: 'Coordinate',
      value: 'COORDINATE',
    },
    {
      label: 'Template',
      value: 'TEMPLATE'
    }
  ];

  readonly templateEditorTypes = [
    {
      label: 'wysiwyg',
      value: 'WYSIWYG',
    },
    {
      label: 'html',
      value: 'HTML',
    },
  ];

  printHasParams(): boolean {
    if(!this.print.parameters) {
      return false;
    }

    return Object.values(this.print.parameters).length > 0;
  }

  translatePrintParam(param: string): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return param in printParameterTranslation ? printParameterTranslation[param] : `<${param}>`;
  }

  getAttrBoolValue(attr: PrintAttributeType): boolean {
    return getAttrBoolValue(this.print, attr);
  }

  getAttr(attr: PrintAttributeType): PrintAttribute {
    return getAttrFromPrint(this.print, attr);
  }

  onPageSheetBreakChange(): boolean {
    if (!this.getAttrBoolValue('PAGE_SHEET_BREAK_ENABLE')) {
      this.getAttr('PAGE_SHEET_BREAK_HEIGHT').value = this.getAttr('PAGE_SHEET_BREAK_AFTER_LINE_NO').value = 0;
    }
    return true; // continue condition chaining
  }
}

nxModule.component('printsDetailsGeneralConfiguration', {
  templateUrl,
  bindings: {
    printParameters: '<',
    print: '=',
    onChange: '<'
  },
  controller: PrintsDetailsGeneralConfiguration,
});