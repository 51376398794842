import nxModule from "nxModule";
import _, { constant } from "lodash";

import ReportService from "../../../../react/report/ReportService"
import systemPropertyService from "system/systemPropertyService";

import templateUrl from "./custom-report.template.html";

interface Report {
  name: string; 
  code?: string; 
}

class CustomReport {
  private reportService: ReportService; 

  title: string = "Custom reports"; 
  reports: Report[] = [];

  constructor() { 
    this.reportService = new ReportService();

    const reportNames: string = systemPropertyService.getProperty("CUSTOM_REPORT_NAMES") || ""; 
    if (reportNames.trim() === "") {
      return;
    }

    this.reports = reportNames.split(",")
      .map(reportName => { 
        return {
          name: reportName.trim(), 
          code: reportName.trim().replace(/ /g, "_")
        };
      });

    this.reportService.readAvailableFileReports().then(reportsInTool => {

      this.reports = this.reports.filter(report => {
        const reportName = report.name.split("-")[0].trim();
        return reportsInTool.find(report => report.includes(reportName));
      });
      
    });

  }

  openLink(report: Report): string {
    return `#!/custom-report/${report.code}`
  }
}

nxModule.component('customReport', {
  templateUrl,
  controller: CustomReport
});
