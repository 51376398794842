import _ from "lodash";

import nxModule from 'nxModule';
import './report-view.style.less';

const templateUrl = require('./report-view.template.html');

const keepOnlyKeys = (obj, keys) => {
  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      delete obj[key];
    }
  });
}

const commonReportDescriptionToKeep = [
  'Report name',
  'Branch',
  'Generated on',
  'Generated by',
  'Organization',
  'TIN number',
  'Checked by',
  'Approved by'
];

nxModule.component('reportView', {
  templateUrl,
  bindings: {
    reportContent: '<',
    container: '<',
    lazyDisplay: '<', // if not present, default value is true
    descriptor: '<'
  },
  controller: function ($scope) {
    const that = this;
    that.container = that.container || null;
    that.lazyDisplay = that.lazyDisplay ?? true;

    $scope.$watch('$ctrl.reportContent', () => {
      if (!that.reportContent) return;

      that.reportGenerated = true;
      that.reportColumnsNum = findColumnNumber(that.reportContent);
      that.hasData = checkIfHasData(that.reportContent);

      if(that.descriptor && that.descriptor.outputTypes && that.descriptor.outputTypes.includes('XLS','CSV')) {
        keepOnlyKeys(that.reportContent[0].reportDescription, commonReportDescriptionToKeep);
      }
    
      that.reportContent.forEach(sheet => sheet.limit = 100);
    });

    that.increaseLimit = () => {
      if(!that.reportContent || !that.lazyDisplay) {
        return;
      }

      const notFullyDisplayedSheet = that.reportContent.find(sheet => sheet.limit < sheet.rows.length);
      if(notFullyDisplayedSheet) {
        notFullyDisplayedSheet.limit += 100;
      }
    };

    function checkIfHasData (reports) {
      return _(reports).map((r) => r.rows).flatten().value().length > 0
    }

    function findColumnNumber (reports) {
      return _(reports).map((report) => {
        return _(report.rows).map((a) => a.cells.length + report.xoffset).max();
      }).max();
    }

  }
});
