import $ from 'jquery';
import nxModule from 'nxModule';
import systemPropertyService from 'system/systemPropertyService';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

const templateUrl = require('./support-case-picker.template.html');
nxModule.component('supportCasePicker', {
  templateUrl,
  controller: function ($scope, $compile, $route, $location, breadcrumbs,
                        commandAccessChecker, authentication, loader) {
    const that = this;
    that.supportCase = null;

    that.supportCases = [];
    const loaderId = loader.show();

    const populateSupportCases = async () => {
      const accessChecker = await commandAccessChecker.canExecuteCommandAsync();
      const allCases = [{
        code: 'misc-clear-system-cache',
        description: 'Clear system cache',
        hasAccess: true // no command to execute
      }, {
        code: 'undo-close-counters',
        description: 'Undo CLOSE COUNTERS',
        hasAccess: accessChecker('UndoCloseCounterCommand')
      }, {
        code: 'update-product-ownership',
        description: 'Update product ownership',
        hasAccess: accessChecker('UpdateProductOwner')
      }, {
        code: 'create-access-rules',
        description: 'Create missing access rules',
        hasAccess: true // no command to execute
      }, {
        code: 'update-branch-asset-counter',
        description: 'Update branch asset counter',
        hasAccess: accessChecker('UpdateBranchAssetCounter')
      }, {
        code: 'update-user-asset-counter',
        description: 'Update user asset counter',
        hasAccess: accessChecker('UpdateUserAssetCounter')
      }, {
        code: 'push-failed-sod',
        description: 'Push failed start of day',
        hasAccess: true // no command to execute
      }, {
        code: 'push-failed-eod',
        description: 'Push failed end of day',
        hasAccess: true // no command to execute
      }, {
        code: 'push-failed-ledger-sync',
        description: 'Push failed ledger sync',
        hasAccess: true // no command to execute
      }, {
        code: 'process-backdated-transactions',
        description: 'Process backdated transactions',
        hasAccess: accessChecker('ProcessBackdatedTransactions')
      }, {
        code: 'update-product-branch',
        description: 'Update product branch',
        hasAccess: accessChecker('UpdateProductBranch')
      }, {
        code: 'update-system-property',
        description: 'Update system property',
        hasAccess: accessChecker('UpdateSystemProperty')
      }, {
        code: 'delete-running-holiday',
        description: 'Delete running holiday',
        hasAccess: accessChecker('DeleteRunningHoliday')
      }, {
        code: 'delete-running-bank-holiday',
        description: 'Delete running bank holiday',
        hasAccess: accessChecker('DeleteRunningBankHoliday')
      }, {
        code: 'batch-account-status-update',
        description: 'Batch account status update to dormant',
        hasAccess: accessChecker('UpdateAccountStatusToDormant')
      }, {
        code: 'activate-accounts',
        description: 'Activate accounts',
        hasAccess: accessChecker('ActivateAccounts')
      }, {
        code: 'batch-edit-loan-accrual',
        description: 'Edit loan accrual and accrual limit',
        hasAccess: accessChecker('BatchEditLoanInterestAccrual')
      }, {
        code: 'force-closing-accounts',
        description: 'Force closing accounts',
        hasAccess: accessChecker('ForceClosingAccounts')
      }, {
        code: 'update-loan-maturity-date',
        description: 'Update loan maturity date',
        hasAccess: accessChecker('UpdateLoanMaturityDate')
      }, {
        code: 'update-loan-transaction-type',
        description: 'Update loan transaction type',
        hasAccess: accessChecker('BatchUpdateLoanTransactionType')
      }, {
        code: 'update-loan-amortization-count',
        description: 'Update loan amortization count',
        hasAccess: accessChecker('UpdateLoanAmortizationCount')
      }, {
        code: 'update-loan-product-types',
        description: 'Update loan product types',
        hasAccess: accessChecker('UpdateLoanProductTypes')
      }, {
        code: 'batch-create-product-lock',
        description: 'Batch create product lock',
        hasAccess: accessChecker('BatchCreateProductLock')
      }, {
        code: 'batch-product-unlock',
        description: 'Batch product unlock',
        hasAccess: accessChecker('BatchProductUnlock')
      }, {
        code: 'resolve-processing-issues',
        description: 'Resolve processing issues',
        hasAccess: accessChecker('MarkProcessingIssueAsResolved')
      }, {
        code: 'upload-custom-product-categories',
        description: 'Upload custom product categories',
        hasAccess: accessChecker('UploadBatchProductCategories')
      }, {
        code: 'update-cif-branch',
        description: 'Update cif branch',
        hasAccess: accessChecker('UpdateCifBranch')
      }, {
        code: 'update-branch-system-date',
        description: 'Update branch system date',
        hasAccess: accessChecker('UpdateBranchSystemDate')
      }, {
        code: 'process-start-of-day',
        description: 'Process start of day',
        hasAccess: systemPropertyService.getProperty('MIGRATION_MODE_ENABLED') === 'TRUE' && accessChecker('ProcessStartDayStepsBatch')
      }, {
        code: 'perform-loan-moratorium',
        description: 'Perform loan moratorium',
        hasAccess: accessChecker('PerformLoanMoratorium')
      }, {
        code: 'recalculate-loan-eir',
        description: 'Recalculate loan EIR',
        hasAccess: accessChecker('RecalculateEffectiveInterestRate')
      }, {
        code: 'reset-ledger-balance',
        description: 'Reset GL balances',
        hasAccess: accessChecker('ResetLedgerBalanceFromCSV')
      }, {
        code: 'upload-chart-of-accounts',
        description: 'Upload chart of accounts',
        hasAccess: accessChecker('CreateLedgerTemplate')
      }, {
        code: 'upload-instapay-members',
        description: 'Upload Instapay members',
        hasAccess: accessChecker('UploadInstapayMembers')
      }, {
        code: 'synchronize-scheduled-jobs',
        description: 'Synchronize scheduled jobs',
        hasAccess: accessChecker('SynchronizeScheduledSystemPropertyJobs')
      }, {
        code: 'remove-active-devices',
        description: 'Remove active devices',
        hasAccess: accessChecker('RemoveActiveDevices')
      }, {
        code: 'recalculate-metadata-for-all-loans',
        description: 'Recalculate metadata for all loans',
        hasAccess: accessChecker('UpdateMetadataForAllLoans')
      }, {
        code: 'update-loan-metadata-mode',
        description: 'Update loan metadata mode',
        hasAccess: accessChecker('UpdateLoanMetadataMode')
      }, {
        code: 'verify-pesonet-transaction',
        description: 'Verify PESONet transaction',
        hasAccess: authentication.permissions['PESONET_READ']
      }, {
        code: 'update-geo-location-dictionary',
        description: 'Update geo location dictionary',
        hasAccess: accessChecker('UpdateGeoLocationDictionary')
      }, {
        code: 'update-officers',
        description: 'Update officers',
        hasAccess: accessChecker('BatchUpdateOfficers')
      }];

      that.supportCases = allCases
        .filter(sc => sc.hasAccess)
        .sort((a, b) => a.description.localeCompare(b.description));
      loader.dismiss(loaderId);
    };

    populateSupportCases();

    setBreadcrumbs(breadcrumbs, 'support-topic', `Misc support`);

    that.changeSupportCase = () => {
      const selectedSupportCase = that.supportCases
        .find(sup => sup.code === that.supportCase);
      if (selectedSupportCase?.path) {
        $location.path(selectedSupportCase.path);
        return;
      }

      const newScope = $scope.$new(true, $scope);
      const html = `<${that.supportCase}/>`;
      const element = $('#support');
      element.html($compile(html)(newScope));
    };
  }
});
